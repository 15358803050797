


















import { Component, Vue, Ref } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalAdminCustomerClassroomManagerAdd from '@/components/organisms/v3/ModalAdminCustomerClassroomManagerAdd.vue'
import ModalUserNameEdit from '@/components/organisms/ModalUserNameEdit.vue'
import ModalConfirmGdlsCode from '@/components/organisms/ModalConfirmGdlsCode.vue'

@Component({
  components: {
    ButtonBase,
    TableBase,
    ModalAdminCustomerClassroomManagerAdd,
    ModalUserNameEdit,
    ModalConfirmGdlsCode,
  },
})
export default class ClassroomManager extends Vue {
  private branchCode = ''

  private branchName = ''

  private branchUsers: object[] = []

  private count = 0

  private managerTableBaseFields: object[] = [
    { key: 'name', label: '教室長氏名', thStyle: 'width: 400px' },
    { key: 'gdlsCode', label: 'GDLSコード' },
    { key: 'reissue', label: '再発行' },
    { key: 'gid', label: 'G-ID' },
    { key: 'delete', label: '' },
  ]

  private managerTableBaseLinks: string[] = ['name']

  private managerTableBaseButtons: string[] = ['gdlsCode', 'reissue', 'delete']

  @Ref() modalAddManager!: ModalAdminCustomerClassroomManagerAdd
  @Ref() modalEditManager!: ModalUserNameEdit
  @Ref() modalConfirmGdls!: ModalConfirmGdlsCode

  private showAdminAdd(): void {
    this.modalAddManager.showAddManager(parseInt(this.$route.params.id))
  }

  private showAdminNameEdit(user: { id: number; nickname: string }): void {
    this.modalEditManager.showWithUser(user.id, user.nickname)
  }

  private showGdlsCode(user: { nickname: string; gdlsCode: string }): void {
    this.modalConfirmGdls.setManager(user.nickname, user.gdlsCode)
  }

  /**
   * ボタンの色（ボタン操作制御）
   * 教室長数が二件になったら追加ボタンを非活性にする
   */
  private get colortype(): string {
    return this.count < 2 ? 'blue' : 'pointer-events-none'
  }

  /**
   * 教室長一覧を取得する
   */
  private async loadUsers() {
    await Vue.prototype.$http.httpWithToken.get(`/branches/${this.$route.params.id}/users`).then((res: any) => {
      this.branchCode = res.data.branch.code
      this.branchName = res.data.branch.name
      this.branchUsers = res.data.users.map(
        (user: { id: number; nickname: string; gdlsCode: string; isGidRegistered: boolean }) => {
          return {
            id: user.id,
            name: {
              onclick: this.showAdminNameEdit,
              variable: { id: user.id, nickname: user.nickname },
              name: user.nickname,
            },
            gdlsCode: [
              {
                onclick: this.showGdlsCode,
                variable: { nickname: user.nickname, gdlsCode: user.gdlsCode },
                name: '確認',
              },
            ],
            reissue: [{ onclick: this.reissueGdls, variable: user.id, name: '再発行' }],
            gid: user.isGidRegistered ? '済' : '未',
            delete: [{ onclick: this.destroyUser, variable: user.id, name: '削除' }],
          }
        }
      )
      this.count = res.data.count
    })
  }

  /**
   * GDLSコードを再発行する
   */
  private async reissueGdls(id: number) {
    if (!confirm('再発行しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .post(`/users/${id}/gdlsCode`)
      .then(() => {
        this.loadUsers()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * 教室長を削除する
   */
  private async destroyUser(id: number) {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/users/${id}`)
      .then(() => {
        this.loadUsers()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  private async mounted() {
    this.loadUsers()
  }
}
