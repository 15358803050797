











import { Component, Prop, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalConfirmGdlsCode extends Mixins(ModalBaseMethod) {
  private managerName = ''

  private manegerGdls = ''

  @Prop()
  subjectTitle?: string

  public async setManager(nickname: string, gdlsCode: string) {
    this.managerName = nickname
    this.manegerGdls = gdlsCode
    this.show()
  }
}
