










import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalUserNameEdit extends Mixins(ModalBaseMethod) {
  private id = 0

  private nickname = ''

  @Prop()
  title?: string

  @Prop()
  subjectTitle?: string

  @Prop({ default: 'blue' })
  color!: string

  /**
   * バリデーションエラー
   */
  private get nameErrors(): string[] {
    const errors = []
    if (this.nickname !== '') {
      if (this.nickname.length > 20) {
        errors.push(`${this.subjectTitle}は20文字までです`)
      }
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    return this.nickname.length > 0 && this.nameErrors.length === 0 ? this.color : 'pointer-events-none'
  }

  /**
   * ユーザ情報を受け取り、モーダル表示
   */
  public async showWithUser(id: number, nickname: string) {
    this.id = id
    this.nickname = nickname
    this.show()
  }

  private async updateUser() {
    await Vue.prototype.$http.httpWithToken
      .patch(`/users/${this.id}`, {
        nickname: this.nickname,
      })
      .then(() => {
        this.$emit('hide-model')
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
